import { useContext, createContext } from 'react';

import { useDocusignTemplates as useDocusignTemplatesQuery } from 'services/docusignTemplates';

const DocusignTemplatesContext = createContext(null);

export const DocusignTemplatesContextProvider = ({ children }) => {
  const { data, isLoading, refetch } = useDocusignTemplatesQuery();

  return (
    <DocusignTemplatesContext.Provider
      value={{
        templates: data ?? [],
        isLoading,
        refetch
      }}
    >
      {children}
    </DocusignTemplatesContext.Provider>
  );
};

export function useDocusignTemplates() {
  const context = useContext(DocusignTemplatesContext);

  if (!context) {
    throw new Error('useDocusignTemplates must be used within a DocusignTemplatesContextProvider');
  }

  return context;
}
