import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const generateDocumentDraft = async (email, templateId) => {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/users/${email}/docusign/templates/${templateId}`,
    {}
  );
};

export const useDocusignTemplates = (companyId, email) => {
  return useQuery({
    queryKey: ['docusignTemplates'],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/docusign/templates`
        );
        return response?.data || [];
      } catch (e) {
        return [];
      }
    }
  });
};
